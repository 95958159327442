import React from "react"
import "./contact.css"
import {MdOutlineEmail} from "react-icons/md"
import {AiOutlineLinkedin} from "react-icons/ai"
import { useRef } from "react";
import emailjs from "emailjs-com";



const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_vwc6g1k", "template_7w6tin8", form.current, "iQC-8x1MFJRCMSgxg")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset()
  };

  return (
    <section id="contact">

      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">

        <div className="contact__options">

          <article className="contact__option">

            <MdOutlineEmail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>daniele.barile.lavoro@gmail.com</h5>
            <a href="mailto:daniele.barile.lavoro@gmail.com">Send a message</a>

          </article>

          <article className="contact__option">

            <AiOutlineLinkedin className="contact__option-icon"/>
            <h4>LinkedIn</h4>
            <h5>Daniele Barile</h5>
            <a href="https://www.linkedin.com/in/danielebarile/" target="_blank">Send a message</a>
            
          </article>

        </div>

        <form ref={form} onSubmit={sendEmail}>

          <input type="text" name="name" placeholder="Your Full Name" required/>
          <input type="email" name="email" placeholder="Your Email" required/>
          <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className="btn btn-primary">Send</button>

        </form>

      </div>

    </section>
  )
}

export default Contact