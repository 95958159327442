import React from "react"
import "./error.css"



const Error = () => {
  return (
    <div className="error">

      <div className="copy-container center-xy">

        <p>oops, 404 error, try something else lol</p>
        <h6>(try to find the easter egg)</h6>

        <br />

        <h5>if you're looking for a hidden thing, it isn't here haha</h5>
        <small>o forse è proprio questa, chissà</small>

      </div>

    </div>

  )
}

export default Error